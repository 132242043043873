<template>
  <div class="overflow-y-scroll scrollbar-hide h-full">
    <div class="bg-mask"></div>
    <div class="bg-circles"></div>
    <div class="relative m-auto mx-4 mt-16 z-10">
      <div class="back-btn-wrapper">
        <button class="back-btn" @click="()=> this.$router.go(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" width="7.41" height="12" viewBox="0 0 7.41 12">
            <path id="ic_chevron_right_24px" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(-8.59 -6)" fill="#fff"/>
          </svg>
        </button>
      </div>
      <div class="comment_wrapper">
        <p class="header_comment">در خواست ها</p>
        <div class="comment" >
          <img src="../../assets/images/icons/comment_avatar.svg" alt="">
          <div class="comment__detail">
            <h6>{{user.first_name + ' ' + user.last_name}}</h6>
            <div class="mt-4"><span class="inline p-0 text-right">شماره تماس</span> <strong class="font-medium text-sm mr-4">{{data[0].contact}}</strong></div>
            <div class="mt-4"><span class="inline p-0 text-right">زمان بازدید</span> <strong class="font-medium text-sm mr-4">{{data[0].visit_time}}</strong></div>
            <div class="mt-4"><span>توضیحات ملک</span>
              <div class="text-white text-sm mt-2">{{data[0].description}}</div>
            </div>
            <div class="text-left text-sm opacity-50">{{data[0].created_at}}</div>
          </div>
        </div>
        <div class="comment realestate" v-for="(item , i) in data" :key="i">
          <router-link :to="{name : 'RealEstatePageCustomer',params :{id :item.real_estate.id }}">
            <img :src="baseUrl+item.real_estate.logo" alt="">
            <div class="comment__detail">
              <h6> بنگاه {{item.real_estate.name}} </h6>
              <div class="text-white text-xs mt-2">بنگاه {{item.real_estate.name}} جهت  بازدید از ملک شما
                اعلام  آمادگی کرده است</div>
            </div>
          </router-link>

          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "../../plugins/axios";
import {mapir, mapMarker} from "mapir-vue";

export default {
  name: "NotificationsDetail",
  components :{
    mapir,
    mapMarker
  },
  data(){
    return{
      data : null,
      mapToken : this.$store.state.map.token,
      marker : [],
      user : null,
      baseUrl : this.$store.state.general.baseURL
    }
  },
  methods:{
    handleUnseen(){

    }
  },
  created() {
    axios.post('/ad-requests/list' ,{
      estate_ad_send_id : this.$route.params.id,
      status: [
        "accepted",
      ]
    }).then(({data})=>{
      this.data = data.data.data;
      this.user = JSON.parse(localStorage.getItem('user'))
    })
  }
}
</script>

<style scoped>

.comment_wrapper .comment__detail span{
  padding: 0 !important;
  display: inline !important;
  text-align: right !important;
}
.btn-notif{
  display: flex;
  justify-content: center;
}
.btn-notif a{
  margin-top: 1rem;
  padding: 0.7rem 1rem;
  text-align: center;
  font-size: 13px;
  border: 1px solid #FF7400;
  border-radius: 1rem;
}
.btn-notif .seen{
  margin-left: 0.5rem;
  background: #644B92;
}
#icon-map{
  border: none !important;
  width: 2.5rem !important;
  height: 2.5rem;
}
</style>